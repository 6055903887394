<template>
  <v-card>
    <div class="timeline-title">
      <h2 class="white--text">Latest Activity</h2>
    </div>
    <dynamic-scroller
      style="height: 300px;"
      v-if="sortedItems.length > 0"
      :items="sortedItems"
      :min-item-size="125"
      class="scroller"
    >
      <template v-slot:default="{ item }">
        <dynamic-scroller-item
          :item="item"
          :active="true"
          :size-dependencies="[
            item.problem_statement,
            item.summary,
            item.outcome,
            item.update_heading,
            item.update_text,
          ]"
          :data-index="item.id"
        >
          <v-timeline dense class="custom-timeline">
            <v-timeline-item
              :color="getDotColor(item)"
              :key="item.id"
              right
              :small="
                (!colorCodeByCenter && 'folder' in item) ||
                  (item.start_date && !item.completed_date && colorCodeByCenter)
              "
            >
              {{
                (item.created_date ||
                  item.completed_date ||
                  item.start_date ||
                  item.update_date) | formatDate
              }}
              <template v-if="item.completed_date">
                <div class="text-h6">
                  Project Completed:
                  <a :href="`/projects/${item.id}`" class="project-link">{{
                    item.name
                  }}</a>
                </div>
                <p class="custom-text-truncate">
                  {{ item.outcome || item.summary }}
                </p>
              </template>
              <template v-else-if="item.start_date">
                <div class="text-h6">
                  Project Started:
                  <a :href="`/projects/${item.id}`" class="project-link">{{
                    item.name
                  }}</a>
                </div>
                <p class="custom-text-truncate">
                  {{ item.problem_statement || item.summary }}
                </p>
              </template>
              <template v-else-if="item.update_heading">
                <div class="text-h6">
                  {{ item.project ? "Project" : "Program Cohort" }} Updated:
                  <a
                    :href="
                      item.project
                        ? `/projects/${item.project}`
                        : `/programs/${item.program}`
                    "
                    class="project-link"
                  >
                    {{ item.project ? item.project_name : item.program_name }}
                  </a>
                </div>
                <div class="font-weight-bold">{{ item.update_heading }}</div>
                <p class="custom-text-truncate">{{ item.update_text }}</p>
              </template>
              <template v-else>
                <div class="text-h6">
                  File Added:
                  <span
                    v-if="downloading && item.id === downloadingAttachmentId"
                  >
                    <v-progress-circular
                      indeterminate
                      size="20"
                      color="primary"
                    ></v-progress-circular>
                  </span>
                  <span v-else>
                    <a
                      href="#"
                      @click.prevent="downloadFile(item)"
                      class="file-link"
                    >
                      {{ item.name }}
                    </a>
                  </span>
                </div>
              </template>
            </v-timeline-item>
          </v-timeline>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <div v-else class="empty-timeline-message">
      No activity to display.
    </div>
  </v-card>
</template>

<style>
.empty-timeline-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #888;
}
.timeline-title {
  padding: 10px;
  background-color: #2e2e2e;
}

.custom-timeline {
  /* margin: 10px; */
  background: #ffffff;
  padding: 0px 10px 0vw 10px;
  padding-top: calc(0.5vw + 10px) !important;
}

.v-timeline-item__dot {
  transform: translateX(-10px);
}
.theme--light.v-timeline .v-timeline-item__dot {
  background: #eeeeee;
}
</style>

<script>
import axios from "axios";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  props: {
    filesEndPoint: {
      type: String,
      required: false,
      default: "",
    },
    projectsEndPoint: {
      type: String,
      required: false,
      default: "",
    },
    updatesEndPoint: {
      type: String,
      required: false,
      default: "",
    },
    colorCodeByCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },
  data: () => ({
    files: [],
    projects: [],
    updates: [],
    centers: {
      BCPI: "var(--blue)",
      GovEx: "var(--purple)",
    },
    downloading: false,
    downloadingAttachmentId: null,
  }),

  computed: {
    sortedItems() {
      const allItems = [...this.projects, ...this.files, ...this.updates];
      const currentDate = new Date();

      const filteredItems = allItems.filter((item) => {
        const itemDate = this.getLatestDate(item);
        return itemDate !== null && itemDate <= currentDate;
      });

      const sortedItems = filteredItems.sort((a, b) => {
        const laterDateA = this.getLatestDate(a);
        const laterDateB = this.getLatestDate(b);
        return laterDateB - laterDateA; // Sorting in descending order
      });

      return sortedItems;
    },
  },
  created() {
    if (this.filesEndPoint) {
      this.getFiles();
    }

    if (this.projectsEndPoint) {
      this.getProjects();
    }

    if (this.updatesEndPoint) {
      this.getUpdates();
    }
  },

  methods: {
    getFiles: async function() {
      let url = `${this.filesEndPoint}&ordering=-created_date`;
      if (!this.colorCodeByCenter) {
        while (url) {
          let response = await this.$api.get(url);
          await this.files.push.apply(this.files, response.data.results);
          url = response.data.next;
        }
      }
    },

    getProjects: async function() {
      let url = `${this.projectsEndPoint}&ordering=date`;
      while (url) {
        let response = await this.$api.get(url);
        await this.projects.push.apply(this.projects, response.data.results);
        url = response.data.next;
      }
    },

    async getUpdates() {
      let url = this.updatesEndPoint;
      this.updates.splice(0, this.updates.length);
      while (url) {
        let response = await this.$api.get(url);
        this.updates.push(...response.data.results);
        url = response.data.next;
      }
    },

    getLatestDate(item) {
      const startDate = item.start_date ? new Date(item.start_date) : null;
      const completedDate = item.completed_date
        ? new Date(item.completed_date)
        : null;
      const createdDate = item.created_date
        ? new Date(item.created_date)
        : null;
      const updateDate = item.update_date ? new Date(item.update_date) : null;

      const dates = [startDate, completedDate, createdDate, updateDate].filter(
        (date) => date
      );

      return dates.length > 0 ? new Date(Math.max(...dates)) : null;
    },

    getDotColor(item) {
      if (this.colorCodeByCenter) {
        if (item.update_date) {
          return "var(--green)";
        }
        return this.centers[item.award_center] || "var(--light-grey)";
      } else {
        let color =
          "folder" in item
            ? "var(--aqua)"
            : item.status === "COMP" || item.status === "CNCLD"
            ? "var(--dark-grey)"
            : "var(--green)";
        return color;
      }
    },

    downloadFile(attachment) {
      this.downloadingAttachmentId = attachment.id;
      this.downloading = true;
      axios
        .get(
          `${process.env.VUE_APP_API}attachments/${attachment.id}/download/`,
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = attachment.name;
          link.click();
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        })
        .finally(() => {
          this.downloading = false; // Reset downloading state to false
        });
    },
  },
};
</script>
