import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticClass:"timeline-title"},[_c('h2',{staticClass:"white--text"},[_vm._v("Latest Activity")])]),(_vm.sortedItems.length > 0)?_c('dynamic-scroller',{staticClass:"scroller",staticStyle:{"height":"300px"},attrs:{"items":_vm.sortedItems,"min-item-size":125},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('dynamic-scroller-item',{attrs:{"item":item,"active":true,"size-dependencies":[
          item.problem_statement,
          item.summary,
          item.outcome,
          item.update_heading,
          item.update_text ],"data-index":item.id}},[_c(VTimeline,{staticClass:"custom-timeline",attrs:{"dense":""}},[_c(VTimelineItem,{key:item.id,attrs:{"color":_vm.getDotColor(item),"right":"","small":(!_vm.colorCodeByCenter && 'folder' in item) ||
                (item.start_date && !item.completed_date && _vm.colorCodeByCenter)}},[_vm._v(" "+_vm._s(_vm._f("formatDate")((item.created_date || item.completed_date || item.start_date || item.update_date)))+" "),(item.completed_date)?[_c('div',{staticClass:"text-h6"},[_vm._v(" Project Completed: "),_c('a',{staticClass:"project-link",attrs:{"href":("/projects/" + (item.id))}},[_vm._v(_vm._s(item.name))])]),_c('p',{staticClass:"custom-text-truncate"},[_vm._v(" "+_vm._s(item.outcome || item.summary)+" ")])]:(item.start_date)?[_c('div',{staticClass:"text-h6"},[_vm._v(" Project Started: "),_c('a',{staticClass:"project-link",attrs:{"href":("/projects/" + (item.id))}},[_vm._v(_vm._s(item.name))])]),_c('p',{staticClass:"custom-text-truncate"},[_vm._v(" "+_vm._s(item.problem_statement || item.summary)+" ")])]:(item.update_heading)?[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.project ? "Project" : "Program Cohort")+" Updated: "),_c('a',{staticClass:"project-link",attrs:{"href":item.project
                      ? ("/projects/" + (item.project))
                      : ("/programs/" + (item.program))}},[_vm._v(" "+_vm._s(item.project ? item.project_name : item.program_name)+" ")])]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.update_heading))]),_c('p',{staticClass:"custom-text-truncate"},[_vm._v(_vm._s(item.update_text))])]:[_c('div',{staticClass:"text-h6"},[_vm._v(" File Added: "),(_vm.downloading && item.id === _vm.downloadingAttachmentId)?_c('span',[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"20","color":"primary"}})],1):_c('span',[_c('a',{staticClass:"file-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadFile(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])])]],2)],1)],1)]}}],null,false,253896955)}):_c('div',{staticClass:"empty-timeline-message"},[_vm._v(" No activity to display. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }